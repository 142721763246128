<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible="dialogShow"
      :close-on-click-modal="false"
      width="550px"
      @close="close"
    >
      <div class="enable-tip">
        <p class="tip" v-if="instructions">{{ instructions }}</p>
        <div class="tip-sure">
          <slot></slot>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">取消</el-button>
        <el-button
          type="primary"
          :loading="sureBtnLoading"
          @click.native="sureHandle"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogTitle: {
      type: String,
      default: "提示",
    },
    dialogShow: {
      type: Boolean,
      default: false,
    },
    sureBtnLoading: {
      type: Boolean,
      default: false,
    },
    instructions: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
    sureHandle() {
      this.$emit("sureHandle");
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;
}

.tip-sure {
  margin-top: 10px;
  color: #333333;
  font-size: 16px;
  text-align: center;
}
</style>

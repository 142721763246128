<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      @handleChange="handleChange"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        slot="merchantId"
        class="el_from"
        :label-width="'80px'"
        label="商家ID"
      >
        <el-input
          clearable
          v-model="merchantId"
          placeholder="请输入商家ID"
        ></el-input>
      </el-form-item>
      <div style="text-align: right; margin-bottom: 20px" slot="AddSlot">
        <el-button
          size="small"
          type="success"
          @click="$refs['configurationDig'].open()"
          >统计数据配置</el-button
        >
        <el-button
          size="small"
          type="warning"
          @click="$refs['timeQuoteMerchantDia'].open()"
          >限时报价设置</el-button
        >
        <el-button size="small" type="danger" @click="openTimeLimit"
          >限时抢单设置</el-button
        >
        <!-- <el-button size="small" type="success" @click="openAuto">自动报价设置</el-button> -->
        <el-dropdown trigger="click" style="font-size: 0.85em">
          <el-button type="success" size="small" class="c-btn">
            自动报价设置 <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <span
              v-for="role in roles"
              :key="role.id"
              @click="updateRole(role.id)"
            >
              <el-dropdown-item>{{ role.roleName }}</el-dropdown-item>
            </span>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" style="font-size: 0.85em">
          <el-button size="small" type="warning" class="c-btn">
            报价设置<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <span @click="setOffer">
              <el-dropdown-item>小米一根线</el-dropdown-item>
            </span>
            <span @click="$refs['quoteSettingHonorDia'].open()">
              <el-dropdown-item>华为</el-dropdown-item>
            </span>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="$router.push('/merchant/add')"
          >添加商家</el-button
        >
      </div>
    </GlobalForm>
    <!-- 限时抢单弹框 -->
    <el-dialog
      title="限时抢单设置"
      :visible.sync="timeLimitOrderShow"
      :close-on-click-modal="false"
      width="900px"
      @closed="timeLimitOrderShow = false"
    >
      <div class="addparto" style="margin-bottom: 15px">
        说明：开启设置限时抢单后，在对应时间段下单的订单，回收商需在抢单时长内完成抢单报价，超出不可报价
      </div>
      <div
        class="fund-title"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span style="color: #333; font-weight: 700">限时抢单规则</span>
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="addRule('add')"
          >添加规则</el-button
        >
      </div>
      <el-table
        border
        stripe
        size="small"
        :maxHeight="500"
        :data="timeLimitOrderList"
        :isPagination="false"
      >
        <!-- 序号 -->
        <el-table-column label="序号" width="50" prop="index" align="center">
          <template v-slot="{ row }">
            <div>{{ row.index }}</div>
          </template>
        </el-table-column>
        <!-- 时间段 -->
        <el-table-column label="时间段" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.limitExclusiveStart }}-{{ row.limitExclusiveEnd }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="限时抢单时长"
          prop="limitExclusiveLength"
          align="center"
        >
          <template slot-scope="{ row }">
            <div>{{ row.limitExclusiveLength }} <span>分钟</span></div>
          </template>
        </el-table-column>
        <el-table-column label="开启限时抢单" prop="isEnable" align="center">
          <template slot-scope="{ row }">
            <div class="service_charge">
              <el-switch
                v-model="row.isEnable"
                active-text="开"
                inactive-text="关"
                inactive-color="#b6b9d0"
                @change="(e) => timeLimitOpen(e, row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="{ row }">
          <el-button type="text" @click="addRule('edit', row)">编辑</el-button>
          <span class="delet-btn" @click="deleteCurrentTime(row)">删除</span>
        </el-table-column>
      </el-table>
      <div style="height: 40px; width: 100%"></div>
    </el-dialog>
    <!-- 限时抢单规则设置弹框 -->
    <el-dialog
      :title="ruleTitle"
      :visible.sync="ruleAddShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="ruleAddShowClose"
    >
      <!-- 时间段 -->
      <div class="flex limit_time mt-20">
        <div style="margin-right: 42px">时间段：</div>
        <el-time-select
          size="small"
          :clearable="false"
          placeholder="起始时间"
          v-model="saveRuleParam.limitExclusiveStart"
          :picker-options="{
            start: '00:00',
            step: '01:00',
            end: '23:00',
          }"
        >
        </el-time-select>
        <div style="margin: 0 10px">~</div>
        <el-time-select
          size="small"
          :clearable="false"
          placeholder="结束时间"
          v-model="saveRuleParam.limitExclusiveEnd"
          :picker-options="{
            start: '01:00',
            step: '01:00',
            end: '24:00',
            minTime: saveRuleParam.limitExclusiveStart,
          }"
        >
        </el-time-select>
      </div>
      <!-- 限时抢单时长 -->
      <div class="flex mt-20">
        <span>限时抢单时长：</span>
        <el-input
          style="width: 366px !important"
          placeholder="请输入大于10的整数"
          v-model="saveRuleParam.limitExclusiveLength"
          clearable
          @input="timeLimitChange"
        >
        </el-input>
        <span style="margin-left: 6px">分钟</span>
      </div>
      <!-- <div class="command mt-20" v-if="ruleTitle == '编辑规则'">
        <div style="margin-right:30px;">动态口令：</div>
        <el-input style="width: 363px !important;" v-model="saveRuleParam.command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div> -->
      <div style="margin: 30px 0; text-align: right">
        <span slot="footer">
          <el-button @click.native="ruleAddShow = false">取消</el-button>
          <el-button type="primary" @click.native="submitTimeRule()"
            >确定</el-button
          >
        </span>
      </div>
    </el-dialog>
    <!-- 开启限时抢单弹框 -->
    <el-dialog
      :title="timeLimitOpenProps.title"
      :visible.sync="timeLimitOpenProps.show"
      :close-on-click-modal="false"
      width="500px"
      @closed="timeLimitSwithClose"
    >
      <div style="color: #ff8080; margin-bottom: 20px">
        说明：{{
          timeLimitOpenProps.title == "开启提示" ? "启用" : "关闭"
        }}后，该时间段不再限制抢单时长
      </div>
      <div class="tip-sure">
        是否确认{{
          timeLimitOpenProps.title == "开启提示" ? "开启" : "关闭"
        }}限时抢单？
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="timeLimitCommand"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="timeLimitSwithClose">取消</el-button>
        <el-button type="primary" @click.native="timeLimitCommandSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除限时抢单弹框 -->
    <el-dialog
      title="删除提示"
      :visible.sync="timeDeletShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="timeDeletClosed"
    >
      <div class="tip-sure">是否确认删除该时间段抢单设置？</div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="timeDeletCommand"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="timeDeletShow = false">取消</el-button>
        <el-button type="primary" @click.native="timeDeletSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- **************************** -->
    <QuoteSettingHonorDia ref="quoteSettingHonorDia" />
    <el-dialog
      class="set-offer"
      title="报价设置"
      :visible.sync="offerDialogVisible"
      :close-on-click-modal="false"
      width="680px"
    >
      <div class="unit_info">
        <div class="unit_info_title">回收商报价次数</div>
        <div class="mt-20">
          <span style="color: #ff687b; font-size: 14px"
            >说明：修改后即时生效，修改报价次数不含首次报价。</span
          >
        </div>
        <div class="flex mt-20">
          <div style="width: 130px">修改报价次数：</div>
          <el-input
            placeholder="请输入"
            v-model.trim="maxCount"
            @input="
              (val) => (maxCount = val.replace(/[^\d]/g, '').replace(/^0$/, ''))
            "
          >
            <template slot="append">次</template>
          </el-input>
        </div>
      </div>
      <div class="unit_info">
        <div class="unit_info_title">
          同串号重复订单报价设置<span style="color: #706f7e"
            >（仅限外部订单）</span
          >
        </div>
        <div class="mt-20">
          <span style="color: #ff687b; font-size: 14px"
            >说明:
            功能生效时，若同串号的订单报价金额在1000元以下，系统自动帮回收商出价，1000元以上机子由指定回收商报价。若功能不生效，同串号订单不限金额仍按新订单处理，需要所有回收商自行报价。</span
          >
        </div>
        <div class="flex mt-20">
          <div style="margin-right: 10px">开启同串号重复订单系统自动报价：</div>
          <el-switch
            v-model="isImeiAutoQuote"
            active-text="开"
            inactive-text="关"
          >
          </el-switch>
        </div>
        <div class="flex mt-20" v-if="isImeiAutoQuote">
          <div style="margin-right: 10px">同一串号重复第</div>
          <el-input-number
            size="small"
            v-model="imeiCount"
            :min="1"
            :max="2"
            @keydown.native="channelInputLimit"
          ></el-input-number>
          <div style="margin-left: 10px">次系统自动报价</div>
        </div>
        <div class="flex mt-20" v-if="isImeiAutoQuote">
          <div style="margin-right: 10px">选择生效外部渠道：</div>
          <el-checkbox-group v-model="imeiCompany">
            <el-checkbox label="xm">XM零售通渠道</el-checkbox>
            <el-checkbox label="ygx">一根线渠道</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="unit_info">
        <div class="unit_info_title">
          多次议价（从第二次议价起算）报价设置<span style="color: #706f7e"
            >（仅限外部订单）</span
          >
        </div>
        <div class="mt-20">
          <span style="color: #ff687b; font-size: 14px"
            >说明:
            功能生效时，第二次议价起系统自动推高价/推送给上次取价商家报价方便为门店拉高价格；若功能关闭，则第二次议价起，议价结果默认返回上次取价商家报价。</span
          >
        </div>
        <div class="flex mt-20">
          <div style="margin-right: 10px">
            是否开启多次议价后，让回收商参与议价/自动返回当前高价：
          </div>
          <el-switch
            v-model="isMoreExpPirce"
            active-text="开"
            inactive-text="关"
          >
          </el-switch>
        </div>
        <div class="flex mt-20" v-if="isMoreExpPirce">
          <div style="margin-right: 10px">选择生效外部渠道：</div>
          <el-checkbox-group v-model="moreExpPirceCompany">
            <el-checkbox label="xm">XM零售通渠道</el-checkbox>
            <el-checkbox label="ygx">一根线渠道</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="flex mt-20">
        <div style="width: 90px">动态口令：</div>
        <el-input
          v-model.trim="command"
          placeholder="请输入动态口令"
          @input="(val) => (command = val.replace(/[^\d]/g, ''))"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="cancelSetOffer()">取消</el-button>
        <el-button type="primary" @click.native="submitSetOffer()"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 小米订单自动报价设置 -->
    <el-dialog
      title="自动报价设置"
      :visible.sync="autoOfferShow"
      :close-on-click-modal="false"
      width="850px"
    >
      <div>
        <span style="color: red; font-size: 14px"
          >说明：自动报价设置目前仅适用于XM订单，，若未触发异常情况，则自动报价=预估价×统一系数；若触发异常情况，则自动报价=预估价×异常系数</span
        >
      </div>
      <div class="button_top mt-20">
        <div class="radiobtn">
          <div class="faultBtn">
            <div
              v-bind:class="this.clickShow === '01' ? 'left' : 'right'"
              @click="BtnClick('01')"
            >
              基础设置
            </div>
            <div
              v-bind:class="this.clickShow === '02' ? 'left' : 'right'"
              @click="BtnClick('02')"
            >
              计算参数设置
            </div>
          </div>
        </div>
      </div>
      <div v-if="clickShow == '01'" style="color: #333; font-size: 16px">
        <div class="flex">
          <div style="margin-right: 10px">是否开启系统自动报价：</div>
          <el-switch
            v-model="isOpen"
            active-text="开"
            inactive-text="关"
            active-value="01"
            inactive-value="02"
          >
          </el-switch>
        </div>
        <div class="flex auto_time mt-20">
          <div style="margin-right: 10px">自动报价时间段设置：</div>
          <el-time-select
            size="small"
            :clearable="false"
            placeholder="起始时间"
            v-model="startDate"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '23:00',
            }"
          >
          </el-time-select>
          <div style="margin: 0 10px">~</div>
          <el-time-select
            size="small"
            :clearable="false"
            placeholder="结束时间"
            v-model="endDate"
            :picker-options="{
              start: '01:00',
              step: '01:00',
              end: '24:00',
              minTime: startDate,
            }"
          >
          </el-time-select>
        </div>
        <div class="explain mt-20">
          <div class="remarks-title">以下情况系统不自动报价:</div>
          <div class="remarks-li">
            发起补拍订单：2分50秒时，任一回收商发起补拍，系统不自动报价;
          </div>
          <!-- <div class="remarks-li">
            发起拒绝报价：2分50秒时，任一回收商拒绝报价，系统不自动报价;
          </div> -->
          <div class="remarks-li">XM订单返回预估价为0，本单系统不自动报价;</div>
          <div class="remarks-li">XM订单的功能项为空，本单系统不自动报价;</div>
          <div class="remarks-li">平板、笔记本、其他分类，系统不自动报价;</div>
        </div>
      </div>
      <div v-if="clickShow == '02'" style="padding-bottom: 20px">
        <div>
          <span style="color: red; font-size: 14px"
            >1.特殊功能项：除“机身外观”“屏幕外观”以外的其他功能项;</span
          >
        </div>
        <div>
          <span style="color: red; font-size: 14px"
            >2.高风险订单：回收商拒绝报价、抢到后取消抢单或跳过则认为该订单为高风险订单;</span
          >
        </div>
        <div>
          <span style="color: red; font-size: 14px"
            >3.异常系数使用：若订单同时触发多项异常，则计算自动报价的异常系数默认取最小值;</span
          >
        </div>
        <GlobalTable
          class="mt-20"
          ref="GlobalTable"
          :columns="regionColumns"
          :data="regionList"
          :maxHeight="500"
          :isPagination="false"
        >
          <el-table-column
            label="采货侠预估价"
            width="135px"
            slot="rank"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>{{ row.lower }}＜区间≤{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="是否开启自动报价"
            width="120px"
            slot="isOpen"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-switch
                v-model="row.isOpen"
                active-text="开"
                inactive-text="关"
                active-value="01"
                inactive-value="02"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="统一系数"
            slot="ratioUnify"
            width="100px"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-input
                size="small"
                v-model.trim="row.ratioUnify"
                placeholder="请输入"
                @blur="inputBlur(row, 'ratioUnify')"
                @input="limitInput($event, row, 'ratioUnify')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="异常系数（不能开机）"
            width="150px"
            slot="ratioUnableStart"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-input
                size="small"
                v-model.trim="row.ratioUnableStart"
                placeholder="请输入"
                @blur="inputBlur(row, 'ratioUnableStart')"
                @input="limitInput($event, row, 'ratioUnableStart')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="异常系数（特殊功能项异常）"
            slot="ratioSpecialError"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-input
                size="small"
                v-model.trim="row.ratioSpecialError"
                placeholder="请输入"
                @blur="inputBlur(row, 'ratioSpecialError')"
                @input="limitInput($event, row, 'ratioSpecialError')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="异常系数（高风险订单）"
            slot="ratioHighRisk"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-input
                size="small"
                v-model.trim="row.ratioHighRisk"
                placeholder="请输入"
                @blur="inputBlur(row, 'ratioHighRisk')"
                @input="limitInput($event, row, 'ratioHighRisk')"
              ></el-input>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
      <div>
        最近修改：{{ updateInfo.updateName }} - {{ updateInfo.updateAcc }}（{{
          updateInfo.updateTime
        }}）
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="autoOfferShow = false">取消</el-button>
        <el-button type="primary" @click.native="submitAutoOffer()"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 非小米订单自动报价设置 -->
    <el-dialog
      title="自动报价设置"
      :visible.sync="autoOfferShow2"
      :close-on-click-modal="false"
      @close="autoCancel"
      width="850px"
    >
      <div>
        <span style="color: red; font-size: 14px" v-if="Autoquotationtype == 2"
          >说明：仅针对繁星矩阵订单、LX外部订单，若未触发异常情况，则自动报价=预估价×统一系数；若触发异常情况，则自动报价=预估价×异常系数</span
        >
        <span style="color: red; font-size: 14px" v-if="Autoquotationtype == 3"
          >说明：自动报价设置用于嗨回收订单，若旧机功能项正常，则自动报价=预估价×统一系数；若旧机触发不能开机/特殊功能项异常，则自动报价=预估价×异常系数</span
        >
      </div>
      <div class="button_top mt-20">
        <div class="radiobtn">
          <div class="faultBtn">
            <div
              v-bind:class="this.clickShow === '01' ? 'left' : 'right'"
              @click="BtnClick('01')"
            >
              基础设置
            </div>
            <div
              v-bind:class="this.clickShow === '02' ? 'left' : 'right'"
              @click="BtnClick('02')"
            >
              计算参数设置
            </div>
          </div>
        </div>
      </div>
      <div v-if="clickShow == '01'" style="color: #333; font-size: 16px">
        <div class="flex">
          <div style="margin-right: 10px">是否开启系统自动报价：</div>
          <el-switch
            v-model="isOpen"
            active-text="开"
            inactive-text="关"
            active-value="01"
            inactive-value="02"
          >
          </el-switch>
        </div>
        <div class="flex auto_time mt-20">
          <div style="margin-right: 10px">自动报价时间段设置：</div>
          <el-time-select
            size="small"
            :clearable="false"
            placeholder="起始时间"
            v-model="startDate"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '23:00',
            }"
          >
          </el-time-select>
          <div style="margin: 0 10px">~</div>
          <el-time-select
            size="small"
            :clearable="false"
            placeholder="结束时间"
            v-model="endDate"
            :picker-options="{
              start: '01:00',
              step: '01:00',
              end: '24:00',
              minTime: startDate,
            }"
          >
          </el-time-select>
        </div>
        <div class="flex auto_time mt-20">
          <div style="margin-right: 10px">设置自动报价机型：</div>
          <el-radio-group v-model="radioPhone" @change="radioChange">
            <el-radio :label="0" :value="0">全部机型</el-radio>
            <el-radio :label="1" :value="1">指定机型</el-radio>
          </el-radio-group>
        </div>
        <div
          style="display: flex; margin-left: 20px; margin-top: 20px"
          v-if="radioPhone == 1"
        >
          <p
            style="
              color: rgb(245, 154, 35);
              text-decoration: underline;
              cursor: pointer;
            "
            @click="addPhoneModel"
          >
            添加机型
          </p>
          <p style="margin-left: 20px">
            已添加
            <span
              style="
                color: #0981ff;
                text-decoration: underline;
                cursor: pointer;
              "
              @click="editPhoneModel"
              >{{ AcquiredList.length }}个</span
            >
            机型
          </p>
        </div>
        <div class="explain mt-20" v-if="Autoquotationtype == 2">
          <div class="remarks-title">以下情况系统不自动报价:</div>
          <div class="remarks-li">
            未添加自动报价回收商的矩阵，默认不开启自动报价;
          </div>
          <div class="remarks-li">无预估价或预估价为0时，系统不自动报价;</div>
          <div class="remarks-li">功能项为空，系统不自动报价;</div>
          <div class="remarks-li">推送报价时，有商家补拍，系统不自动报价;</div>
        </div>
        <div class="explain mt-20" v-if="Autoquotationtype == 3">
          <div class="remarks-title">以下情况系统不自动报价:</div>
          <div class="remarks-li">非手机分类不自动报价;</div>
          <div class="remarks-li">无预估价不自动报价;</div>
          <div class="remarks-li">订单功能项为空时，不自动报价;</div>
          <div class="remarks-li">
            机型为其他型号，预估价≥200元时，默认报低价(低价由数据库配置);
          </div>
        </div>
      </div>
      <div v-if="clickShow == '02'" style="padding-bottom: 20px">
        <div>
          <span style="color: red; font-size: 14px"
            >1.特殊功能项：除“机身外观”“屏幕外观”以外的其他功能项;</span
          >
        </div>
        <div>
          <span style="color: red; font-size: 14px"
            >2.高风险订单：回收商拒绝报价、抢到后取消抢单或跳过则认为该订单为高风险订单;</span
          >
        </div>
        <div>
          <span style="color: red; font-size: 14px"
            >3.异常系数使用：若订单同时触发多项异常，则计算自动报价的异常系数默认取最小值;</span
          >
        </div>
        <GlobalTable
          class="mt-20"
          ref="GlobalTable"
          :columns="regionColumns"
          :data="regionList"
          :maxHeight="500"
          :isPagination="false"
        >
          <el-table-column
            label="原始预估价"
            width="135px"
            slot="rank"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>{{ row.lower }}＜区间≤{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="是否开启自动报价"
            width="120px"
            slot="isOpen"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-switch
                v-model="row.isOpen"
                active-text="开"
                inactive-text="关"
                active-value="01"
                inactive-value="02"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="统一系数"
            slot="ratioUnify"
            width="100px"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-input
                size="small"
                v-model.trim="row.ratioUnify"
                placeholder="请输入"
                @blur="inputBlur(row, 'ratioUnify')"
                @input="limitInput($event, row, 'ratioUnify')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="异常系数（不能开机）"
            width="150px"
            slot="ratioUnableStart"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-input
                size="small"
                v-model.trim="row.ratioUnableStart"
                placeholder="请输入"
                @blur="inputBlur(row, 'ratioUnableStart')"
                @input="limitInput($event, row, 'ratioUnableStart')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="异常系数（特殊功能项异常）"
            slot="ratioSpecialError"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-input
                size="small"
                v-model.trim="row.ratioSpecialError"
                placeholder="请输入"
                @blur="inputBlur(row, 'ratioSpecialError')"
                @input="limitInput($event, row, 'ratioSpecialError')"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="异常系数（高风险订单）"
            slot="ratioHighRisk"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-input
                size="small"
                v-model.trim="row.ratioHighRisk"
                placeholder="请输入"
                @blur="inputBlur(row, 'ratioHighRisk')"
                @input="limitInput($event, row, 'ratioHighRisk')"
              ></el-input>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
      <div>
        最近修改：{{ updateInfo.updateName }} - {{ updateInfo.updateAcc }}（{{
          updateInfo.updateTime
        }}）
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="autoCancel">取消</el-button>
        <el-button type="primary" @click.native="submitAutoOfferOut()"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加管理报价机型 -->
    <el-dialog
      :title="phoneModel"
      :visible.sync="phoneModelShow"
      :close-on-click-modal="false"
      width="1050px"
    >
      <div>
        <span style="color: #ff687b; font-size: 14px"
          >说明：仅查询手机分类的机型</span
        >
      </div>
      <div style="display: flex; margin-top: 10px">
        <FormSearch
          @searchForm="searchForm"
          :Autoquotationtype="Autoquotationtype"
          :searchData="searchData"
        />
        <el-button
          type="danger"
          style="margin-left: 10px"
          @click="moreDelPhone"
          v-if="phoneModel == '管理报价机型'"
          :disabled="deleteTable.length == 0"
          >批量删除</el-button
        >
      </div>
      <div style="padding-bottom: 20px">
        <GlobalTable
          class="mt-20"
          ref="GlobalTable"
          :isSelection="true"
          :columns="regionColumns"
          :data="modelList"
          :maxHeight="500"
          :isPagination="false"
          @handleSelectionChange="handleSelectionChange"
          v-loading="loading"
        >
          <el-table-column label="所属品牌" slot="rank" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.brandName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="机型名称" slot="isOpen" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="机型来源" slot="ratioUnify" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.channel }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            slot="ratioUnify"
            align="center"
            v-if="phoneModel == '管理报价机型'"
          >
            <template slot-scope="{ row }">
              <el-button type="danger" @click="delPhoneModel(row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="phoneModelShow = false">关闭</el-button>
        <el-button
          type="primary"
          @click.native="getCommitAddPhoneModel"
          v-if="phoneModel == '添加报价机型'"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 立本和闪回收自动报价设置 -->
    <el-dialog
      title="报价设置"
      :visible.sync="lbshpriceShow"
      :close-on-click-modal="false"
      width="660px"
      @closed="commandClosed"
    >
      <div style="color: red; font-size: 14px">
        <span
          >说明：自动报价设置用于{{Autoquotationtype==4?'闪回':'立本'}}渠道订单，自动报价=靓机价×统一系数，无靓机价时不进行自动报价</span
        >
      </div>
      <p style="font-weight: bold; margin: 10px 0; font-size: 16px">统一系数</p>
      <div v-if="Autoquotationtype==4">
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span style="margin-right: 20px">荣耀自动报价系数</span>
          <el-col :span="11">
            <el-input
              size="small"
              placeholder="请输入小于0.8的数字"
              @input="coefficientChange($event, 'ratioHonor')"
              v-model.trim="editPriceForm.ratioHonor"
            ></el-input>
          </el-col>
        </div>
        <div style="display: flex; align-items: center">
          <span style="margin-right: 20px">三星自动报价系数</span>
          <el-col :span="11">
            <el-input
              size="small"
              @input="coefficientChange($event, 'ratioSamsung')"
              placeholder="请输入小于0.8的数字"
              v-model.trim="editPriceForm.ratioSamsung"
            ></el-input>
          </el-col>
        </div>
      </div>
      <div v-if="Autoquotationtype==5">
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span style="margin-right: 20px">SD自动报价系数</span>
          <el-col :span="11">
            <el-input
              size="small"
              placeholder="请输入小于0.8的数字"
              @input="coefficientChange($event, 'ratioSd')"
              v-model.trim="editPriceForm.ratioSd"
            ></el-input>
          </el-col>
        </div>
        <div style="display: flex; align-items: center">
          <span style="margin-right: 20px">BD自动报价系数</span>
          <el-col :span="11">
            <el-input
              size="small"
              @input="coefficientChange($event, 'ratioBd')"
              placeholder="请输入小于0.8的数字"
              v-model.trim="editPriceForm.ratioBd"
            ></el-input>
          </el-col>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 15px;">
        最近修改：<p v-if="editPriceForm.updateName">{{editPriceForm.updateName }}-{{editPriceForm.updateAcc	}}<span v-if="editPriceForm.updateTime">({{editPriceForm.updateTime  }})</span></p><span v-else>--</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="lbshpriceShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="commandBtnLoading"
          @click.native="coefficientSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 立本和闪回收 -->
    <el-dialog
      title="操作提示"
      :visible.sync="coeffShow"
      :close-on-click-modal="false"
      width="400px"
      @closed="commandClosed"
    >
      <div class="tip-sure">是否确认修改自动报价配置？</div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          @input="(val) => (command = val.replace(/[^\d]/g, ''))"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="coeffShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="commandBtnLoading"
          @click.native="commandcoofiiSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="操作提示"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="400px"
      @closed="commandClosed"
    >
      <div class="tip-sure">是否确认修改自动报价配置？</div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          @input="(val) => (command = val.replace(/[^\d]/g, ''))"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="commandBtnLoading"
          @click.native="commandSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <TimeQuoteMerchantDia ref="timeQuoteMerchantDia" />
    <configurationDig ref="configurationDig" />
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
import FormSearch from "./components/FormSearch.vue";
import TimeQuoteMerchantDia from "./dialog/TimeQuoteMerchantDia.vue";
import configurationDig from "./dialog/configurationDig.vue";
import QuoteSettingHonorDia from "./dialog/QuoteSettingHonorDia.vue";
export default {
  name: "Form",
  data() {
    const { merchantName } = this.$route.query;
    let initData = null;
    if (merchantName) {
      initData = {
        staffName: merchantName,
      };
    }
    this.merchantName = merchantName;
    return {
      coeffShow:false,
      editPriceForm: {
        ratioSd: "",
        ratioBd: "",
        ratioHonor:"",
        ratioSamsung:"",
        updateTime:"",
        updateName:"",
        updateAcc:"",
      },
      lbshpriceShow: false,
      // 限时抢单**********
      timeLimitOrderShow: false, //弹框
      timeLimitOrderList: [], //表格数据
      ruleTitle: "", //规则弹框标题
      ruleAddShow: false, //规则弹框
      saveRuleParam: {}, //添加/编辑规则传参
      limitCommand: "", //编辑动态口令
      timeLimitOpenProps: {
        //开启限时抢单
        show: false,
        title: "",
      },
      merchantId: "",
      timeLimitCommand: "", //开启动态口令
      limitRow: {}, //
      timeDeletShow: false, //删除弹框
      timeDeletCommand: "", //删除动态口令
      // *********************
      merchantName: "",
      startTime: "",
      endTime: "",
      loading: false,
      contactPhone: "",
      searchData: {
        brandName: "",
        name: "",
        channel: "",
      },
      initData,
      channel: "",
      radioPhone: 0,
      brandName: "",
      selectTable: [],
      deleteTable: [],
      commitTable: [],
      name: "",
      formItemList: [
        { slotName: "merchantId" },
        // {
        //   key: "merchantId",
        //   type: "input",
        //   labelName: "商家ID",
        //   placeholder: "请输入商家ID",
        // },
        {
          key: "staffName",
          type: "input",
          labelName: "商家名称",
          placeholder: "请输入商家名称",
        },
        {
          key: "shortName",
          type: "input",
          labelName: "商户简称",
          placeholder: "请输入商户简称查询",
        },
        {
          key: "mobile",
          isNumber: true,
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
        },
        {
          labelName: "省市查询",
          key: "provinceId",
          //需要被清除的key，相当于子
          childrenKey: "cityId",
          type: "selectFilterable",
          placeholder: "请选择",
          lableWidth: "80px",
          clear: true,
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "",
          key: "cityId",
          fatherKey: "provinceId",
          type: "selectFilterable",
          placeholder: "请先选择省",
          lableWidth: "0",
          clear: true,
          option: [],
          disabled: true,
          clearFun: () => false,
        },
        {
          key: "entryTime",
          type: "daterange",
          labelName: "入驻时间",
          placeholder: "请选择入驻时间",
          valueFormat: "timestamp",
        },
        {
          key: "isRecommend",
          type: "select",
          labelName: "是否推荐",
          placeholder: "请选择",
          option: [
            {
              label: "是",
              value: "01",
            },
            {
              label: "否",
              value: "02",
            },
          ],
        },
        {
          key: "channel",
          type: "select",
          labelName: "注册渠道",
          placeholder: "请选择注册渠道",
          option: [
            { label: "全部", value: "" },
            { label: "后台", value: "01" },
            { label: "前端", value: "02" },
          ],
        },
        {
          key: "matchServerFeeOnline",
          type: "select",
          lableWidth: "120px",
          labelName: "服务费结算方式",
          placeholder: "请选择",
          option: [
            { label: "线上结算", value: "01" },
            { label: "线下结算", value: "02" },
          ],
        },
        {
          key: "merchantEnterType",
          type: "select",
          lableWidth: "120px",
          labelName: "商家类型",
          placeholder: "请选择",
          option: [
            { label: "正式商家", value: "00" },
            { label: "外部商家", value: "01" },
          ],
        },
        { slot: "AddSlot" },
      ],
      modelList: [],
      formRules: {
        mobile: [
          {
            validator: formValidateMobile,
            trigger: "blur",
          },
        ],
      },
      offerDialogVisible: false,
      maxCount: "",
      command: "",
      modelIdList: [],
      isSearch: false,
      isImeiAutoQuote: false,
      isMoreExpPirce: true,
      imeiCount: 1,
      imeiCompany: [],
      moreDelete: true,
      moreExpPirceCompany: [],
      delListModel: [],
      roles: [
        {
          id: 1,
          roleName: "小米订单设置",
        },
        {
          id: 2,
          roleName: "非小米订单设置",
        },
        {
          id: 3,
          roleName: "嗨回收订单设置",
        },
        {
          id: 4,
          roleName: "闪回订单设置",
        },
        {
          id: 5,
          roleName: "立本订单设置",
        },
      ],
      autoOfferShow: false,
      chooseModel: false,
      autoOfferShow2: false,
      clickShow: "01",
      NonXiaom: false,
      isOpen: "00",
      modelIdList2: [],
      startDate: "09:00",
      moreDel: false,
      endDate: "23:00",
      regionList: [],
      updateInfo: {
        updateName: "--",
        updateAcc: "--",
        updateTime: "--",
      },
      regionColumns: [
        { slotName: "rank" },
        { slotName: "isOpen" },
        { slotName: "ratioUnify" },
        { slotName: "ratioUnableStart" },
        { slotName: "ratioSpecialError" },
        { slotName: "ratioHighRisk" },
      ],
      commandShow: false,
      commandBtnLoading: false,
      phoneModel: "",
      phoneModelShow: false,
      addPhoneNum: "",
      moreDelList: [],
      delList: [],
      searchModelList: [],
      addChooseList: [],
      AcquiredList: [],
      modelDataList: [],
      Autoquotationtype: "",
    };
  },
  created() {
    this.handleConfirm();
    this.getProvince();
  },
  components: {
    FormSearch,
    TimeQuoteMerchantDia,
    QuoteSettingHonorDia,
    configurationDig,
  },
  watch: {
    merchantId: {
      handler(onl, newew) {
        console.log(onl);
        this.merchantId = onl.replace(/\D/g, "");
      },
    },
  },
  methods: {
    coefficientChange(e, name) {
      console.log(e, name);
      this.editPriceForm[name] = Number(e) > 0.8 ? 0.8 : e;
      console.log(this.editPriceForm[name]);
    },
    // 选择小米/非小米自动报价弹窗
    updateRole(val) {
      this.Autoquotationtype = val;
      if (val == 1) {
        this.openAuto();
        this.NonXiaom = false;
        this.modelIdList = [];
      } else if(val==2||val==3) {
        this.NonXiaom = true;
        this.openAuto2();
        this.getAddPhoneModel();
      }else if(val==4||val==5){
        this.getlbshconfig(val)
      }
    },
    getlbshconfig(type){
        _api.getlbshconfig({
          saveType:type==4?'00':'01'
        }).then(res=>{
          this.editPriceForm=res.data
          this.lbshpriceShow=true
        })
    },
    savelbshconfig(){
      _api.savelbshconfig({
        command:this.command,
        saveType:this.Autoquotationtype==4?'00':'01',
        ...this.editPriceForm
      }).then(res=>{
        this.$message.success(res.msg || "操作成功");
        this.coeffShow=false
        this.lbshpriceShow=false
      })
    },
    coefficientSubmit(){
      if(this.Autoquotationtype==4&&(Number(this.editPriceForm.ratioSamsung)<0.01||Number(this.editPriceForm.ratioHonor)<0.01)){
        return   this.$message.error('系数配置不能为空并且需大于0.01');
      }
      if(this.Autoquotationtype==5&&(Number(this.editPriceForm.ratioBd)<0.01||Number(this.editPriceForm.ratioSd)<0.01)){
        return   this.$message.error('系数配置不能为空并且需大于0.01');
      }
      this.coeffShow=true
    },
    commandcoofiiSubmit(){
      this.savelbshconfig()
    },
    // 获取未添加机型列表
    getPhoneNumber() {
      this.loading = true;
      if (this.Autoquotationtype == 2) {
        _api
          .PhoneModelTable({
            brandName: "",
            name: "",
          })
          .then((res) => {
            if (res.code == 1) {
              this.loading = false;
              this.modelList = res.data;
            }
          });
      } else {
        _api
          .haioutModelTable({
            brandName: "",
            name: "",
          })
          .then((res) => {
            if (res.code == 1) {
              this.loading = false;
              this.modelList = res.data;
            }
          });
      }
    },
    // 获取已添加机型列表
    getAddPhoneModel() {
      if (this.Autoquotationtype == 2) {
        _api
          .PhoneAddModelTable({
            brandName: "",
            name: "",
          })
          .then((res) => {
            this.modelDataList = JSON.parse(JSON.stringify(res)).data;
            this.AcquiredList = JSON.parse(JSON.stringify(res)).data;
          });
      } else {
        _api
          .haiAddModelTable({
            brandName: "",
            name: "",
          })
          .then((res) => {
            this.modelDataList = JSON.parse(JSON.stringify(res)).data;
            this.AcquiredList = JSON.parse(JSON.stringify(res)).data;
          });
      }
    },
    // 添加机型后给已添加机型数量赋值，获取到添加前的数组，与添加的数组合并
    getCommitAddPhoneModel() {
      console.log(this.AcquiredList);
      this.AcquiredList.push(...this.selectTable);
      const uniqueArr = this.AcquiredList.filter(
        (item, index) =>
          this.AcquiredList.findIndex((i) => i.id === item.id) === index
      );
      this.AcquiredList = uniqueArr;
      this.phoneModelShow = false;
    },
    // 限时抢单
    openTimeLimit() {
      this.timeLimitOrderShow = true;
      // 请求限时抢单详情
      this.exclusiveList();
    },
    searchForm(data) {
      console.log(data, this.phoneModel);
      if (this.phoneModel == "管理报价机型") {
        this.modelList = [];
        if (this.Autoquotationtype == 3) {
          if (data.brandName || data.name || data.channel) {
            if (!data.channel) {
              if (data.name && !data.brandName) {
                this.AcquiredList.forEach((item) => {
                  if (
                    item.name.toLowerCase().includes(data.name.toLowerCase())
                  ) {
                    this.modelList.push(item);
                  }
                });
              } else if (!data.name && data.brandName) {
                this.AcquiredList.forEach((item) => {
                  if (
                    item.brandName
                      .toLowerCase()
                      .includes(data.brandName.toLowerCase())
                  ) {
                    this.modelList.push(item);
                  }
                });
              } else {
                this.AcquiredList.forEach((item) => {
                  if (
                    item.name.toLowerCase().includes(data.name.toLowerCase()) &&
                    item.brandName
                      .toLowerCase()
                      .includes(data.brandName.toLowerCase())
                  ) {
                    this.modelList.push(item);
                  }
                });
              }
            } else {
              if (data.name && !data.brandName) {
                this.AcquiredList.forEach((item) => {
                  if (
                    item.name.toLowerCase().includes(data.name.toLowerCase()) &&
                    item.channel.includes(data.channel)
                  ) {
                    this.modelList.push(item);
                  }
                });
              } else if (!data.name && data.brandName) {
                this.AcquiredList.forEach((item) => {
                  if (
                    item.brandName
                      .toLowerCase()
                      .includes(data.brandName.toLowerCase()) &&
                    item.channel.includes(data.channel)
                  ) {
                    this.modelList.push(item);
                  }
                });
              } else if (data.name && data.brandName) {
                this.AcquiredList.forEach((item) => {
                  if (
                    item.name.toLowerCase().includes(data.name.toLowerCase()) &&
                    item.brandName
                      .toLowerCase()
                      .includes(data.brandName.toLowerCase()) &&
                    item.channel.includes(data.channel)
                  ) {
                    this.modelList.push(item);
                  }
                });
              } else if (!data.name && !data.brandName) {
                this.AcquiredList.forEach((item) => {
                  if (item.channel.includes(data.channel)) {
                    this.modelList.push(item);
                  }
                });
              }
            }
          } else {
            this.modelList = this.AcquiredList;
          }
        } else {
          if (data.brandName || data.name) {
            if (data.name && !data.brandName) {
              this.AcquiredList.forEach((item) => {
                if (item.name.toLowerCase().includes(data.name.toLowerCase())) {
                  this.modelList.push(item);
                }
              });
            } else if (!data.name && data.brandName) {
              this.AcquiredList.forEach((item) => {
                if (
                  item.brandName
                    .toLowerCase()
                    .includes(data.brandName.toLowerCase())
                ) {
                  this.modelList.push(item);
                }
              });
            } else {
              this.AcquiredList.forEach((item) => {
                if (
                  item.name.toLowerCase().includes(data.name.toLowerCase()) &&
                  item.brandName
                    .toLowerCase()
                    .includes(data.brandName.toLowerCase())
                ) {
                  this.modelList.push(item);
                }
              });
            }
            console.log(data.name);
          } else {
            this.modelList = this.AcquiredList;
          }
        }
      } else {
        if (this.Autoquotationtype == 2) {
          _api.PhoneModelTable(data).then((res) => {
            if (res.code == 1) {
              this.modelList = res.data;
            }
          });
        } else {
          let phnoehiparams = {
            brandName: data.brandName,
            channel: this.Modelsource(data.channel),
            name: data.name,
          };
          _api.haioutModelTable(phnoehiparams).then((res) => {
            if (res.code == 1) {
              this.modelList = res.data;
            }
          });
        }
      }
    },
    // 判断来源
    Modelsource(type) {
      let typeValue = "";
      if (type == "采货侠") {
        typeValue = "chx";
      } else if (type == "分毫") {
        typeValue = "fh";
      } else if (type == "荣耀") {
        typeValue = "honor";
      } else if (type == "嗨回收") {
        typeValue = "hi";
      }
      return typeValue;
    },
    // 非小米添加机型弹窗展示事件
    addPhoneModel() {
      this.phoneModelShow = true;
      this.phoneModel = "添加报价机型";
      this.modelList = [];
      this.getPhoneNumber();
      this.searchData = {
        brandName: "",
        name: "",
        channel: "",
      };
    },
    // 非小米已添加报价机型弹窗展示事件
    editPhoneModel() {
      this.phoneModelShow = true;
      this.phoneModel = "管理报价机型";
      this.modelList = this.AcquiredList;
      this.delList = [];
      this.moreDel = false;
      this.searchData = {
        brandName: "",
        name: "",
        channel: "",
      };
    },
    // 限时抢单详情
    exclusiveList() {
      _api.exclusiveList().then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.timeLimitOrderList = res.data ? res.data : [];
          this.timeLimitOrderList = this.timeLimitOrderList.map((v, index) => {
            return {
              ...v,
              index: index + 1,
            };
          });
        }
      });
    },
    // 删除当前行****
    deleteCurrentTime(row, event, column) {
      this.timeDeletShow = true;
      this.limitRow = row;
      // console.log(row, '4444444444');
      // const rowIndex = this.timeLimitOrderList.indexOf(row)
      // console.log(rowIndex, '当前行index');
      // this.timeLimitOrderList.splice(rowIndex, 1)
    },
    timeDeletClosed() {
      this.timeDeletShow = false;
      this.timeDeletCommand = "";
    },
    timeDeletSubmit() {
      let params = {
        command: this.timeDeletCommand,
        id: this.limitRow.id,
      };
      //请求
      _api.exclusiveRemoveById(params).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.$message.success(res.msg || "操作成功");
          this.timeDeletCommand = "";
          // 刷新限时抢单列表
          this.exclusiveList();
          this.timeDeletShow = false;
        }
      });
    },
    // 添加/编辑规则
    addRule(type, row) {
      this.ruleAddShow = true;
      if (type === "add") {
        //添加规则
        this.ruleTitle = "添加规则";
        //重置数据
        this.saveRuleParam = {};
      } else {
        //编辑规则
        this.ruleTitle = "编辑规则";
        //重置数据
        this.saveRuleParam = row;
      }
      console.log(this.saveRuleParam, "this.saveRuleParamthis.saveRuleParam");
    },
    // 添加/编辑规则确定
    submitTimeRule() {
      // 添加/编辑
      // 限时抢单时长规则（大于10的整数）
      const regex = /^[1-9]\d+$/;
      if (!regex.test(this.saveRuleParam.limitExclusiveLength)) {
        return this.$message.error("限时抢单时长为大于10的整数！");
      }
      // 请求保存接口
      const params = {
        ...this.saveRuleParam,
      };
      _api.exclusiveSave(params).then((res) => {
        if (res.code === 1) {
          if (this.ruleTitle === "添加规则") {
            this.timeLimitOrderList.push({
              ...this.saveRuleParam,
              index: this.timeLimitOrderList.length + 1,
            });
          }
          // 刷新限时抢单列表
          this.exclusiveList();
          this.ruleAddShow = false;
        }
      });
    },
    autoCancel() {
      this.autoOfferShow2 = false;
      this.selectTable = [];
      this.addPhoneNum = "";
    },
    // 非小米自动报价添加机型列表选择事件
    handleSelectionChange(val) {
      if (this.phoneModel != "管理报价机型") {
        this.selectTable = val;
      } else {
        this.deleteTable = val;
      }
    },
    getInclude1(arr1, arr2) {
      let temp = [];
      for (const item of arr2) {
        arr1.find((i) => i === item) ? temp.push(item) : "";
      }
      return temp;
    },
    // 添加/编辑规则 关闭弹框
    ruleAddShowClose() {
      this.ruleAddShow = false;
      this.saveRuleParam = {};
    },
    // 限时抢单时长规则
    timeLimitChange(v) {
      this.saveRuleParam.limitExclusiveLength = v;
      if (parseInt(v) < 0) {
        return (this.saveRuleParam.limitExclusiveLength = null);
      }
      this.saveRuleParam.limitExclusiveLength =
        this.saveRuleParam.limitExclusiveLength
          .replace(/[^0-9.]/g, "") //非0-9.
          .replace(/(^0+)([^.]+)/g, ($1, $2, $3) => $3); //开头的0
    },
    // 开启限时抢单
    timeLimitOpen(e, row) {
      console.log(row, "777777");
      this.limitRow = row;
      if (e) {
        this.timeLimitOpenProps = {
          ...this.timeLimitOpenProps,
          title: "开启提示",
          show: true,
        };
      } else {
        this.timeLimitOpenProps = {
          ...this.timeLimitOpenProps,
          title: "关闭提示",
          show: true,
        };
      }
    },
    // 动态口令弹框关闭
    timeLimitCommandClosed() {
      this.timeLimitCommand = "";
      this.timeLimitOpenProps = {};
    },
    // 提交
    timeLimitCommandSubmit() {
      if (!this.timeLimitCommand) {
        this.$message.error("请输入动态口令");
        return;
      }
      let params = {
        command: this.timeLimitCommand,
        id: this.limitRow.id,
        isEnable: this.limitRow.isEnable,
      };
      //请求
      _api.exclusiveIsEnable(params).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.$message.success(res.msg || "操作成功");
          this.timeLimitOpenProps = {};
          // 刷新限时抢单列表
          this.exclusiveList();
        }
      });
    },
    // 关闭
    timeLimitSwithClose() {
      this.timeLimitOpenProps = {};
      this.timeLimitCommand = "";
      // 刷新限时抢单列表
      this.exclusiveList();
    },
    // **********************
    // 获取省市区
    getProvince() {
      _api.listAudit(0).then((r) => {
        const index = this.formItemList.findIndex(
          (v) => v.key === "provinceId"
        );
        this.formItemList[index].option = r.data.map((v) => {
          return {
            label: v.cityName,
            value: v.id,
          };
        });
      });
    },
    // 获取市区
    getCity(v) {
      _api.listAudit(v).then((r) => {
        const index = this.formItemList.findIndex((v) => v.key === "cityId");
        console.log(v);
        this.formItemList[index].option = r.data.map((v) => {
          return {
            label: v.cityName,
            value: v.id,
          };
        });
      });
    },
    handleConfirm(data, cd) {
      const { merchantName = null } = this.$route.query;
      // console.log(Boolean(data.entryTime));
      if (data) {
        if (data.entryTime === null) {
          (this.startTime = ""),
            (this.endTime = ""),
            (this.merchantName = data.staffName);
          this.contactPhone = data.mobile;
          this.isRecommend = data.isRecommend;
          this.channel = data.channel;
        } else {
          data.entryTime = Array.isArray(data.entryTime) ? data.entryTime : [];
          this.contactPhone = data.mobile;
          (this.merchantName = data.staffName),
            (this.startTime = data.entryTime[0]),
            (this.endTime = data.entryTime[1]),
            (this.isRecommend = data.isRecommend);
          this.channel = data.channel;
        }
      } else {
        this.merchantName = merchantName;
        this.startTime = "";
        this.endTime = "";
        this.contactPhone = "";
        this.isRecommend = "";
        this.channel = "";
      }
      const SeachParams = {
        merchantType: "02",
        merchantId: this.merchantId,
        merchantName: this.merchantName,
        contactPhone: this.contactPhone,
        startTime: this.startTime,
        endTime: this.endTime,
        companyId: 0,
        pageNum: 1,
        pageSize: 10,
        isRecommend: this.isRecommend,
        channel: this.channel,
        cityId: data ? data.cityId : undefined,
        provinceId: data ? data.provinceId : undefined,
        shortName: data ? data.shortName : undefined,
        matchServerFeeOnline: data ? data.matchServerFeeOnline : undefined,
        merchantEnterType: data ? data.merchantEnterType : undefined,
        disuseLoding: () => {
          if (typeof cd === "function") {
            cd();
          }
        },
      };
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
      // this.$emit('handleConfirm',SeachParams)
      // _api.ShopTable(SeachParams).then(res =>{
      //   if(res.code===1){
      //     this.$store.commit('tagsView/POST_MERCHANTDATA',res.data.records)
      //     this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
      //     this.$store.commit('tagsView/POST_TOTAL',res.data.total)
      //   }
      // })
    },
    // 搜索项变化
    handleChange(v1, v2) {
      if (v1.key === "provinceId") {
        const index = this.formItemList.findIndex(
          (v) => v.key === v1.childrenKey
        );
        if (v2) {
          this.formItemList[index].disabled = false;
          this.formItemList[index].placeholder = "请选择市";
          this.getCity(v2);
        } else {
          this.formItemList[index].placeholder = "请先选择省";
          this.formItemList[index].disabled = true;
        }
      }
    },
    // 删除机型
    delPhoneModel(val) {
      this.moreDel = false;
      this.AcquiredList = this.AcquiredList.filter((item) => item.id != val.id);
      this.modelList = this.AcquiredList;
    },
    // 批量删除机型
    moreDelPhone() {
      this.moreDel = true;
      this.deleteTable.forEach((item) => {
        this.AcquiredList.forEach((item2, index) => {
          if (item.id == item2.id) {
            this.AcquiredList.splice(index, 1);
          }
        });
      });
      this.modelList = this.AcquiredList;
      // this.modelList = filtered
      // this.AcquiredList=filtered
      // this.modelIdList = this.modelList.map(item => {return item.id.toString()})
      console.log(this.AcquiredList, "moreList");
    },
    // 报价设置
    setOffer() {
      this.findOrderQuoteUpdateMax();
      this.command = "";
      this.offerDialogVisible = true;
    },
    // 获取报价修改次数
    findOrderQuoteUpdateMax() {
      _api.findOrderQuoteUpdateMax().then((res) => {
        if (res.code === 1) {
          this.maxCount = res.data.orderQuoteUpdateMax;
          this.isImeiAutoQuote =
            res.data.isImeiAutoQuote == "true" ? true : false;
          this.isMoreExpPirce =
            res.data.isMoreExpPirce == "true" ? true : false;
          this.imeiCount = res.data.imeiCount;
          this.moreExpPirceCompany = res.data.moreExpPirceCompany;
          this.imeiCompany = res.data.imeiCompany;
        }
      });
    },
    // 弹框确定操作
    submitSetOffer() {
      if (!this.maxCount) {
        this.$message.error("请输入可修改报价次数");
        return;
      }
      this.imeiCompany.forEach((item, i) => {
        if (!item) {
          this.imeiCompany.splice(i, 1);
        }
      });
      this.moreExpPirceCompany.forEach((item, i) => {
        if (!item) {
          this.moreExpPirceCompany.splice(i, 1);
        }
      });
      if (
        (this.isImeiAutoQuote && !this.imeiCompany.length) ||
        (this.isMoreExpPirce && !this.moreExpPirceCompany.length)
      ) {
        this.$message.error("请选择生效的外部渠道");
        return;
      }
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      let params = {
        command: this.command,
        maxCount: this.maxCount,
        isImeiAutoQuote: this.isImeiAutoQuote,
        isMoreExpPirce: this.isMoreExpPirce,
        moreExpPirceCompany: this.moreExpPirceCompany,
        imeiCompany: this.imeiCompany,
        imeiCount: this.imeiCount,
      };
      _api.updateOrderQuoteUpdateMax(params).then((res) => {
        if (res.code === 1) {
          this.$message.success("操作成功!");
          this.offerDialogVisible = false;
        }
      });
    },
    // 弹框取消操作
    cancelSetOffer() {
      this.offerDialogVisible = false;
    },

    channelInputLimit(e) {
      e.returnValue = "";
    },
    // 小米订单开启自动报价弹框
    openAuto() {
      this.clickShow = "01";
      this.autoOfferShow = true;
      this.getQuoteConfig();
    },
    // 非小米订单弹窗开启
    openAuto2() {
      this.clickShow = "01";
      this.autoOfferShow2 = true;
      this.NonXiaom = true;
      this.getQuoteConfig2();
    },
    // 非小米订单获取自动报价配置
    getQuoteConfig2() {
      if (this.Autoquotationtype == 2) {
        _api.getNotQuoteConfig().then((res) => {
          if (res.code == 1) {
            this.startDate = res.data.startDate;
            this.endDate = res.data.endDate;
            this.isOpen = res.data.isOpen;
            this.radioPhone = res.data.autoType == "02" ? 1 : 0;
            this.regionList = res.data.regionList;
            this.updateInfo = {
              updateName: res.data.updateName || "--",
              updateAcc: res.data.updateAcc || "--",
              updateTime: res.data.updateTime || "--",
            };
          }
        });
      } else {
        _api.gethaiQuoteConfig().then((res) => {
          if (res.code == 1) {
            this.startDate = res.data.startDate;
            this.endDate = res.data.endDate;
            this.isOpen = res.data.isOpen;
            this.radioPhone = res.data.autoType == "02" ? 1 : 0;
            this.regionList = res.data.regionList;
            this.updateInfo = {
              updateName: res.data.updateName || "--",
              updateAcc: res.data.updateAcc || "--",
              updateTime: res.data.updateTime || "--",
            };
          }
        });
      }
    },
    radioChange(e) {
      if (e == 1) {
        this.chooseModel = true;
      }
    },
    // 自动报价弹框确认
    submitAutoOffer() {
      this.commandShow = true;
    },
    submitAutoOfferOut() {
      this.commandShow = true;
    },
    BtnClick(type) {
      this.clickShow = type;
    },
    // 开始时间选择
    startTimeChange(e) {
      console.log(e);
    },
    // 获取自动报价配置
    getQuoteConfig() {
      _api.getQuoteConfig().then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.startDate = res.data.startDate;
          this.endDate = res.data.endDate;
          this.isOpen = res.data.isOpen;
          this.regionList = res.data.regionList;
          this.updateInfo = {
            updateName: res.data.updateName || "--",
            updateAcc: res.data.updateAcc || "--",
            updateTime: res.data.updateTime || "--",
          };
        }
      });
    },
    limitInput(value, row, name) {
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.regionList.forEach((item) => {
          if (item.id == row.id) {
            item[name] = "";
          }
        });
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      this.regionList.forEach((item) => {
        if (item.id == row.id) {
          item[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || "";
          if (item[name] > 2) {
            item[name] = "2";
          }
        }
      });
    },
    // 失焦
    inputBlur(row, name) {
      console.log(row);
      switch (name) {
        case "ratioUnify":
          if (!row.ratioUnify) {
            this.$message.error("不能为空");
            this.regionList.forEach((item) => {
              if (item.id == row.id) {
                item[name] = "1";
              }
            });
          }
          break;
        case "ratioUnableStart":
          if (!row.ratioUnableStart) {
            this.$message.error("不能为空");
            this.regionList.forEach((item) => {
              if (item.id == row.id) {
                item[name] = "1";
              }
            });
          }
          break;
        case "ratioSpecialError":
          if (!row.ratioSpecialError) {
            this.$message.error("不能为空");
            this.regionList.forEach((item) => {
              if (item.id == row.id) {
                item[name] = "1";
              }
            });
          }
          break;
        default:
          break;
      }
      this.regionList.forEach((item) => {
        if (item.id == row.id) {
          console.log(item[name]);
          if (item[name].toString().indexOf(".") == item[name].length - 1) {
            item[name] = item[name].replace(".", "");
          }
          if (item[name] == 0) {
            item[name] = 1;
            this.$message.error("不能为0");
          }
        }
      });
    },
    commandClosed() {
      this.command = "";
    },
    submitPhoneModel() {},
    // 自动报价设置提交事件
    commandSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.commandBtnLoading = true;
      let data = {
        command: this.command,
        endDate: this.endDate,
        isOpen: this.isOpen,
        regionList: this.regionList,
        startDate: this.startDate,
        autoType: this.radioPhone == 0 ? "01" : "02",
      };
      console.log(data, "data", this.AcquiredList);
      if (this.NonXiaom == true) {
        data.modelIdList = this.AcquiredList.map((item) => item.id);
        console.log(data.modelIdList, "this.modelIdList");
        if (this.Autoquotationtype == 2) {
          _api
            .getPhoneModelTable(data)
            .then((res) => {
              if (res.code == 1) {
                console.log(res);
                this.AcquiredList = [];
                this.$message.success(res.msg || "操作成功");
                this.commandShow = false;
                this.autoOfferShow2 = false;
              }
              this.commandBtnLoading = false;
            })
            .catch(() => {
              this.commandBtnLoading = false;
            });
        } else {
          _api
            .saveHaiModelTable(data)
            .then((res) => {
              if (res.code == 1) {
                console.log(res);
                this.AcquiredList = [];
                this.$message.success(res.msg || "操作成功");
                this.commandShow = false;
                this.autoOfferShow2 = false;
              }
              this.commandBtnLoading = false;
            })
            .catch(() => {
              this.commandBtnLoading = false;
            });
        }
      } else {
        _api
          .saveQuoteConfig(data)
          .then((res) => {
            if (res.code == 1) {
              console.log(res);
              this.$message.success(res.msg || "操作成功");
              this.commandShow = false;
              this.autoOfferShow = false;
            }
            this.commandBtnLoading = false;
          })
          .catch(() => {
            this.commandBtnLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .fund-title {
    margin-bottom: 10px;
    padding: 0 4px;
    font-size: 14px;
  }

  .delet-btn {
    font-size: 14px;
    font-weight: 500;
    color: #ff0000;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
  }

  .addparto {
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
  }

  .service_charge {
    /deep/ .el-switch__label * {
      line-height: 1;
      font-size: 12px;
      display: inline-block;
    }

    /deep/ .el-switch__label {
      position: absolute;
      display: none;
      color: #fff !important;
      font-size: 12px !important;
    }

    /*打开时文字位置设置*/
    /deep/ .el-switch__label--right {
      z-index: 1;
      right: 4px;
    }

    /*关闭时文字位置设置*/
    /deep/ .el-switch__label--left {
      z-index: 1;
      left: 4px;
    }

    /*显示文字*/
    /deep/ .el-switch__label.is-active {
      display: block;
    }

    /*开关宽度*/
    /deep/ .el-switch .el-switch__core,
    /deep/ .el-switch .el-switch__label {
      width: 50px !important;
    }
  }

  /deep/ .el-form--inline .el-form-item {
    margin-right: 30px !important;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .c-btn {
    margin-left: 10px;
    margin-right: 10px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .set-offer {
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }

  .unit_info {
    margin-bottom: 40px;

    .unit_info_title {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      color: #0981ff;
      padding-left: 15px;
    }

    .unit_info_title::before {
      position: absolute;
      content: " ";
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      width: 3px;
      height: 21px;
      background: #0981ff;
      border-radius: 2px;
    }
  }

  .button_top {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    width: 280px;
    height: 32px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;

    .left {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      background: #0981ff;
      border-radius: 20px;
    }

    .right {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }

  .auto_time {
    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 150px !important;
    }
  }

  .limit_time {
    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 170px !important;
    }
  }

  .explain {
    width: 100%;
    background: #f5f6fa;
    border-radius: 10px;
    padding: 15px 20px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #666666;

    .remarks-title {
      width: max-content;
      height: 20px;
      padding: 0 10px;
      color: #ffffff;
      background: rgb(255, 104, 123);
      border-radius: 10px 10px 10px 0px;
    }

    .remarks-li {
      position: relative;
      padding: 0 10px;
      margin-top: 12px;
    }

    .remarks-li::before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background: rgb(255, 104, 123);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 7px;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
